// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.JCXQj7wmPlmxQxgBxGcg {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-right: 1.25rem;
  border-right: 1px solid #392b75;
}

.AzAxXbpA7UVpLq7e4Nzc {
  color: #45348e;
  font-family: "Mulish", sans-serif;

  font-style: normal;
  transition: all 150ms ease-in-out;
  margin-bottom: 0.625rem;
  cursor: pointer;
  text-align: start;
  white-space: nowrap;
  padding: 12px;
  border-radius: 4px;
  width: 100%;
  min-width: 100px;

  &:hover {
    background-color: #f5f5f5;
  }
}

.L0ysem8ovfIDl2bJXw2r {
  background-color: #f5f5f5;
  font-weight: 800;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/molecules/FormNavigationColumn/FormNavigationColumn.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,sBAAsB;EACtB,+BAA+B;AACjC;;AAEA;EACE,cAAc;EACd,iCAAiC;;EAEjC,kBAAkB;EAClB,iCAAiC;EACjC,uBAAuB;EACvB,eAAe;EACf,iBAAiB;EACjB,mBAAmB;EACnB,aAAa;EACb,kBAAkB;EAClB,WAAW;EACX,gBAAgB;;EAEhB;IACE,yBAAyB;EAC3B;AACF;;AAEA;EACE,yBAAyB;EACzB,gBAAgB;AAClB","sourcesContent":[".navigation__menu__container {\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  padding-right: 1.25rem;\n  border-right: 1px solid #392b75;\n}\n\n.navigation__button {\n  color: #45348e;\n  font-family: \"Mulish\", sans-serif;\n\n  font-style: normal;\n  transition: all 150ms ease-in-out;\n  margin-bottom: 0.625rem;\n  cursor: pointer;\n  text-align: start;\n  white-space: nowrap;\n  padding: 12px;\n  border-radius: 4px;\n  width: 100%;\n  min-width: 100px;\n\n  &:hover {\n    background-color: #f5f5f5;\n  }\n}\n\n.navigation__button__active {\n  background-color: #f5f5f5;\n  font-weight: 800;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navigation__menu__container": `JCXQj7wmPlmxQxgBxGcg`,
	"navigation__button": `AzAxXbpA7UVpLq7e4Nzc`,
	"navigation__button__active": `L0ysem8ovfIDl2bJXw2r`
};
export default ___CSS_LOADER_EXPORT___;
