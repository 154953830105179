// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pPyosg8cgbpK9uoO19wh {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 24px;
}

.TLkjWGmJARTSYq9MBQio {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.dbD37TVC37nOABpuRA_g {
  height: -webkit-fill-available;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  color: #45348e;
}
`, "",{"version":3,"sources":["webpack://./src/pages/ListCommissionRules/ListCommissionRules.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,SAAS;AACX;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,8BAA8B;EAC9B,gBAAgB;EAChB,aAAa;EACb,sBAAsB;EACtB,gBAAgB;EAChB,cAAc;AAChB","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  gap: 24px;\n}\n\n.loading__container {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100%;\n}\n\n.page__content {\n  height: -webkit-fill-available;\n  max-height: 100%;\n  display: flex;\n  flex-direction: column;\n  overflow-y: auto;\n  color: #45348e;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `pPyosg8cgbpK9uoO19wh`,
	"loading__container": `TLkjWGmJARTSYq9MBQio`,
	"page__content": `dbD37TVC37nOABpuRA_g`
};
export default ___CSS_LOADER_EXPORT___;
