// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.oONfFumlZPtVY5WY_MWL {
  display: flex;
  flex-direction: row;
  height: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/organisms/ComissionRuleForm/ComissionRuleForm.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,YAAY;AACd","sourcesContent":[".container {\n  display: flex;\n  flex-direction: row;\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `oONfFumlZPtVY5WY_MWL`
};
export default ___CSS_LOADER_EXPORT___;
