import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ButtonProps {
  textBttn: string;
  handleClick: () => void;
  isDisabled: boolean;
  isLoading: boolean;
  isPrimary: boolean;
  isHidden?: boolean;
}

export interface HeaderNavigationState {
  title: string;
  previousAction: (() => void) | null;
  previousActionMovement: string;
  buttonProps: ButtonProps;
  isHidden?: boolean;
}

const initialState: HeaderNavigationState = {
  title: "Comisiones",
  previousAction: null,
  previousActionMovement: "",
  buttonProps: {
    textBttn: "",
    handleClick: () => {},
    isDisabled: false,
    isLoading: false,
    isPrimary: true,
    isHidden: false,
  },
};

const headerNavigationPropsSlice = createSlice({
  name: "headerNavigationProps",
  initialState,
  reducers: {
    updateHeaderTitle: (state, action: PayloadAction<string>) => {
      state.title = action.payload;
    },
    updateHeaderPreviousAction: (state, action: PayloadAction<() => void>) => {
      state.previousAction = action.payload;
    },
    updateHeaderButtonDisabled: (state, action: PayloadAction<boolean>) => {
      state.buttonProps.isDisabled = action.payload;
    },
    updateHeaderButtonLoading: (state, action: PayloadAction<boolean>) => {
      state.buttonProps.isLoading = action.payload;
    },
    updateHeaderButtonText: (state, action: PayloadAction<string>) => {
      state.buttonProps.textBttn = action.payload;
    },
    updateHeaderButtonAction: (state, action: PayloadAction<() => void>) => {
      state.buttonProps.handleClick = action.payload;
    },
    updateHeaderButtonHidden: (state, action: PayloadAction<boolean>) => {
      state.buttonProps.isHidden = action.payload;
    },
  },
});

export const {
  updateHeaderTitle,
  updateHeaderPreviousAction,
  updateHeaderButtonDisabled,
  updateHeaderButtonLoading,
  updateHeaderButtonText,
  updateHeaderButtonAction,
  updateHeaderButtonHidden,
} = headerNavigationPropsSlice.actions;

export default headerNavigationPropsSlice.reducer;
