import { changeLoadingButtonTwo } from "@ReduxService/states/modalAcceptCancelSlice";
import { updateNotification } from "@ReduxService/states/notificationSlice";
import { AppDispatch } from "@ReduxService/index";
import { ICommissionFormValues } from "@Models/interfaces/commissionForm";
import { updateCommission_service } from "@Services/commissions/updateCommission";
import {
  updateHeaderButtonDisabled,
  updateHeaderButtonLoading,
  updateHeaderTitle,
} from "@ReduxService/states/headerNavigationSlice";

interface payloadList {
  news: number[];
  removed: number[];
}

export const updateCommission = async (
  dispatch: AppDispatch,
  commissionId: number,
  formValues: ICommissionFormValues,
  initialFormValues: ICommissionFormValues,
  updatedLists: {
    teamMembers: payloadList;
    services: payloadList;
    products: payloadList;
  },
  setInitialFormValues: (formValues: ICommissionFormValues) => void,
  items: any[],
) => {
  const payload: any = {
    commisionEmployeeId: commissionId,
  };

  if (formValues.name.trim() !== initialFormValues.name.trim()) {
    payload.newName = formValues.name.trim();
  }

  if (
    formValues.rules.salesThreshold !== initialFormValues.rules.salesThreshold
  ) {
    payload.newGoal = Number(formValues.rules.salesThreshold);
  }

  if (formValues.rules.period !== initialFormValues.rules.period) {
    payload.newIdPeriod = formValues.rules.period;
  }

  if (formValues.rules.method !== initialFormValues.rules.method) {
    payload.newIdMethod = formValues.rules.method;
  }

  if (formValues.rules.modality !== initialFormValues.rules.modality) {
    payload.newIdTypeAmount = formValues.rules.modality;
  }

  if (formValues.rules.amount !== initialFormValues.rules.amount) {
    payload.newAmount = Number(formValues.rules.amount);
  }

  if (formValues.conditions.taxes !== initialFormValues.conditions.taxes) {
    payload.conditionTax = formValues.conditions.taxes;
  }

  if (
    formValues.conditions.promotions !== initialFormValues.conditions.promotions
  ) {
    payload.conditionPromotion = formValues.conditions.promotions;
  }

  if (updatedLists.teamMembers.news.length > 0) {
    payload.newMembersIds = updatedLists.teamMembers.news;
  }

  if (updatedLists.teamMembers.removed.length > 0) {
    payload.deleteMembersId = updatedLists.teamMembers.removed;
  }

  if (updatedLists.services.news.length > 0) {
    payload.newPriceServicesIds = updatedLists.services.news;
  }

  if (updatedLists.products.news.length > 0) {
    payload.newProductsIds = updatedLists.products.news;
  }
  const findCommissionDetailIds = (
    items: any[],
    removedServiceIds: number[],
    removedProductIds: number[],
  ): number[] => {
    const deletedItems: number[] = [];

    items.forEach((item) => {
      // Check for removed services
      if (
        item.getCommisionDetailServices &&
        removedServiceIds.includes(
          item.getCommisionDetailServices.priceServiceId,
        )
      ) {
        deletedItems.push(item.commisionDetailId);
      }

      // Check for removed products
      if (
        item.getCommsionDetailProduct &&
        removedProductIds.includes(item.getCommsionDetailProduct.productId)
      ) {
        deletedItems.push(item.commisionDetailId);
      }
    });

    return deletedItems;
  };
  const deletedItems = findCommissionDetailIds(
    items,
    updatedLists.services.removed,
    updatedLists.products.removed,
  );

  if (deletedItems.length > 0) {
    payload.deleteCommisionDetail = deletedItems;
  }

  dispatch(updateHeaderButtonLoading(true));
  dispatch(updateHeaderButtonDisabled(true));

  const response = await updateCommission_service(payload);

  if (response.isSuccess) {
    window.location.href = "/comisiones";
  }

  dispatch(updateHeaderButtonLoading(false));
  dispatch(updateHeaderButtonDisabled(false));
  dispatch(
    updateNotification({
      message: response.message,
      status: response.status,
      toastTimeDuration: 4000,
    }),
  );
};
