import { Route, Routes } from "react-router-dom";
import { COMMISSION_ROUTE } from "@Models/routes";

import { CreateCommission } from "@Pages/CreateCommission/CreateCommission";
import { EditCommission } from "@Pages/EditCommission/EditCommission";
import { ListCommissionRules } from "@Pages/ListCommissionRules/ListCommissionRules";

const ContextRoute = () => {
  return (
    <Routes>
      <Route
        path={COMMISSION_ROUTE.HOME_ROUTE}
        element={<ListCommissionRules />}
      />
      <Route
        path={COMMISSION_ROUTE.CREATE_COMMISSION}
        element={<CreateCommission />}
      />
      <Route
        path={COMMISSION_ROUTE.EDIT_COMMISSION}
        element={<EditCommission />}
      />
    </Routes>
  );
};

export default ContextRoute;
