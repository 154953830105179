// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.yXOTdhCdDYMrEo8dmGkh {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 16px 20px;
  background-color: #fff;
  border: 1px solid #d4d4d4;
  border-radius: 6px;
  gap: 1rem;
  max-width: 730px;
}

.SWDXgyLt4E45w6i4Mlyk {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 1rem;
}

.pJ4sY5lYyYTyf4d55Qne {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 6px;

  color: #4d4d4d;
  font:
    500 0.875rem "Mulish",
    sans-serif;
}

.lIHkTtTYvnYz5qceDlC5 {
  width: 100%;
  margin-top: 1rem;
}

.MzltMjPMG68o9xZJmMAx {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
}

.o5HiVGtEqX3zx3lEGIQ0 {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  color: #4d4d4d;
  font:
    700 0.875rem "Mulish",
    sans-serif;
  cursor: pointer;
}

.GNOl1tbdDscOrJnCAEs5 {
  color: #4d4d4d;
  font:
    400 0.875rem "Mulish",
    sans-serif;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/molecules/CommissionForm/components/styles/Rules.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,uBAAuB;EACvB,eAAe;EACf,kBAAkB;EAClB,sBAAsB;EACtB,yBAAyB;EACzB,kBAAkB;EAClB,SAAS;EACT,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,2BAA2B;EAC3B,uBAAuB;EACvB,eAAe;EACf,SAAS;AACX;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,mBAAmB;EACnB,QAAQ;;EAER,cAAc;EACd;;cAEY;AACd;;AAEA;EACE,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,QAAQ;EACR,cAAc;EACd;;cAEY;EACZ,eAAe;AACjB;;AAEA;EACE,cAAc;EACd;;cAEY;AACd","sourcesContent":[".container {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: flex-start;\n  flex-wrap: wrap;\n  padding: 16px 20px;\n  background-color: #fff;\n  border: 1px solid #d4d4d4;\n  border-radius: 6px;\n  gap: 1rem;\n  max-width: 730px;\n}\n\n.rules__form {\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-start;\n  align-items: flex-start;\n  flex-wrap: wrap;\n  gap: 1rem;\n}\n\n.rules__form__checkbox__container {\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: center;\n  gap: 6px;\n\n  color: #4d4d4d;\n  font:\n    500 0.875rem \"Mulish\",\n    sans-serif;\n}\n\n.conditions__container {\n  width: 100%;\n  margin-top: 1rem;\n}\n\n.conditions__options {\n  display: flex;\n  flex-direction: column;\n  gap: 1rem;\n  margin-top: 1rem;\n}\n\n.label_container {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  gap: 6px;\n  color: #4d4d4d;\n  font:\n    700 0.875rem \"Mulish\",\n    sans-serif;\n  cursor: pointer;\n}\n\n.description {\n  color: #4d4d4d;\n  font:\n    400 0.875rem \"Mulish\",\n    sans-serif;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `yXOTdhCdDYMrEo8dmGkh`,
	"rules__form": `SWDXgyLt4E45w6i4Mlyk`,
	"rules__form__checkbox__container": `pJ4sY5lYyYTyf4d55Qne`,
	"conditions__container": `lIHkTtTYvnYz5qceDlC5`,
	"conditions__options": `MzltMjPMG68o9xZJmMAx`,
	"label_container": `o5HiVGtEqX3zx3lEGIQ0`,
	"description": `GNOl1tbdDscOrJnCAEs5`
};
export default ___CSS_LOADER_EXPORT___;
