import { IModalProps } from "@Models/interfaces/modal";
import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

const initialState: IModalProps = {
  title: "",
  visibility: {
    isVisible: false,
  },
  modalType: null,
  showButtons: {
    showButtonOne: false,
    showButtonTwo: false,
    showButtonClose: false,
  },
  buttonOne: {
    textButtonOne: "",
    actionButtonOne: null,
    isDisabled: false,
    isLoading: false,
  },
  buttonTwo: {
    textButtonTwo: "",
    actionButtonTwo: null,
    isDisabled: false,
    isLoading: false,
  },
  actionButtonClose: null,
  isSmallSize: false,
  noSizeLimit: false,
  footer: null,
};

export const modalAcceptCancelSlice = createSlice({
  name: "modalConfig",
  initialState: initialState,
  reducers: {
    updateModalAcceptCancel(state, action: PayloadAction<IModalProps>) {
      state.title = action.payload.title as string;
      state.visibility = action.payload.visibility;
      state.modalType = action.payload.modalType;
      state.showButtons = action.payload.showButtons;
      state.buttonOne = action.payload.buttonOne;
      state.buttonTwo = action.payload.buttonTwo;
      state.actionButtonClose = action.payload.actionButtonClose;
      state.isSmallSize = action.payload.isSmallSize;
      state.noSizeLimit = action.payload.noSizeLimit;
      state.footer = action.payload.footer;
    },
    closeModal(state) {
      state.visibility.isVisible = false;
    },
    changeLoadingButtonTwo(state, action) {
      state.buttonTwo.isDisabled = action.payload;
      state.buttonTwo.isLoading = action.payload;
    },
    changeDisabledButtonTwo(state, action) {
      state.buttonOne.isDisabled = action.payload;
    },
  },
});

export default modalAcceptCancelSlice.reducer;
export const {
  updateModalAcceptCancel,
  closeModal,
  changeLoadingButtonTwo,
  changeDisabledButtonTwo,
} = modalAcceptCancelSlice.actions;
