import React from "react";
// @ts-ignore
import { SlideNotification, ModalAcceptCancel } from "@viuti/recursos";
import { clearNotification } from "@ReduxService/states/notificationSlice";
import { useAppDispatch, useAppSelector } from "@Hooks/store";
import {
  DELETE_COMMISSION_MODAL_TYPE,
  FILTER_COMMISSIONS_MODAL_TYPE,
} from "@Models/const/modalTypes";
import { FilterCommissionModal } from "@Components/ui/organisms/FilterCommissionModal/FilterCommissionModal";
import { DeleteCommissionModal } from "@Components/ui/organisms/DeleteCommissionModal/DeleteCommissionModal";

export const GlobalComponents = () => {
  const dispatch = useAppDispatch();
  const notificationState = useAppSelector((state) => state.notificationConfig);
  const modalState = useAppSelector((state) => state.modalConfig);

  let children: React.ReactNode | null = null;

  switch (modalState.modalType) {
    case FILTER_COMMISSIONS_MODAL_TYPE:
      children = <FilterCommissionModal />;
      break;
    case DELETE_COMMISSION_MODAL_TYPE:
      children = <DeleteCommissionModal />;
      break;
    default:
      break;
  }

  return (
    <>
      <SlideNotification
        state={notificationState}
        clearStatus={() => dispatch(clearNotification())}
      />
      <ModalAcceptCancel {...modalState}>{children}</ModalAcceptCancel>
    </>
  );
};
