import { employeesBaseUrl, getData } from "@Services/constServices";

export interface IGetEmployeesResponse {
  isSuccess: boolean;
  status: number;
  message: string;
  data: any;
}

export const getEmployees_service =
  async (): Promise<IGetEmployeesResponse> => {
    const response = getData(
      `${employeesBaseUrl}/Team/TeamMembers`,
      "Empleados obtenidos correctamente",
      "Ocurrió un error inesperado al obtener los empleados",
    );

    return response;
  };
