import {
  changeLoadingButtonTwo,
  closeModal,
} from "@ReduxService/states/modalAcceptCancelSlice";
import { updateNotification } from "@ReduxService/states/notificationSlice";
import { disableCommission } from "@ReduxService/states/commissionsSlice";
import { deleteCommission_service } from "@Services/commissions/deleteCommission";

export const deleteCommission = async (id, dispatch) => {
  dispatch(changeLoadingButtonTwo(true));
  const response = await deleteCommission_service(id);

  if (response.isSuccess) {
    dispatch(closeModal());
    dispatch(disableCommission(id));
  } else {
    dispatch(changeLoadingButtonTwo(false));
  }

  dispatch(
    updateNotification({
      message: response.message,
      status: response.status,
      toastTimeDuration: 4000,
    }),
  );
};
