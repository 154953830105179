import { useEffect, useRef, useState } from "react";
import style from "./ListCommissionRules.module.css";
import {
  CriticalErrorPage,
  HeaderNavigation,
  PaginationWrapper,
  SearchAndFilter,
  // @ts-ignore
} from "@viuti/recursos";
import { useAppDispatch, useAppSelector } from "@Hooks/store";
import { useNavigate } from "react-router-dom";

import CommissionsRulesTable from "@Components/ui/molecules/CommissionsRulesTable/CommissionsRulesTable";

import { getCommissions } from "@Adapters/commissions/getCommissions.adapter";
import { LoadingPulseIcon } from "@Models/icons";
import { openFilterCommissionsModal } from "./functions/filterCommissions";
import {
  updateHeaderButtonAction,
  updateHeaderButtonDisabled,
  updateHeaderButtonHidden,
  updateHeaderButtonLoading,
  updateHeaderButtonText,
  updateHeaderPreviousAction,
  updateHeaderTitle,
} from "@ReduxService/states/headerNavigationSlice";

export const ListCommissionRules = () => {
  const dispatch = useAppDispatch();
  const headerNavigationProps = useAppSelector(
    (state) => state.headerNavigationProps,
  );

  const navigate = useNavigate();
  const [searchInput, setSearchInput] = useState("");
  const [currentPagination, setCurrentPagination] = useState(1);

  const { commissions, totalPages, loading, error } = useAppSelector(
    (state) => state.commissions,
  );
  const itemsPerPage = 10;

  // Reference for table container
  const tableContainerRef = useRef(null);

  useEffect(() => {
    (async () => {
      await getCommissions(dispatch, currentPagination);
    })();
  }, [currentPagination]);

  useEffect(() => {
    dispatch(updateHeaderTitle("Comisiones"));
    dispatch(updateHeaderPreviousAction(null));
    dispatch(updateHeaderButtonText("Crear regla"));
    dispatch(updateHeaderButtonLoading(false));
    dispatch(updateHeaderButtonDisabled(false));
    dispatch(updateHeaderButtonAction(() => navigate("/crear-regla")));
    dispatch(updateHeaderButtonHidden(false));
  }, []);

  if (loading)
    return (
      <figure className={style.loading__container}>
        <img src={LoadingPulseIcon} alt="Cargando..." />
      </figure>
    );

  if (error) return <CriticalErrorPage />;

  return (
    <div id="viuti-front-mainContent">
      <div className={style.container}>
        <HeaderNavigation {...headerNavigationProps} />
        {/* <SearchAndFilter
          placeholder="Buscar por nombre de regla"
          searchInput={searchInput}
          setSearchInput={setSearchInput}
          handleOpenFilter={(e) => openFilterCommissionsModal(e, dispatch)}
          isFilterAvailable={false}
        /> */}
        <section ref={tableContainerRef} className={style.page__content}>
          <CommissionsRulesTable items={commissions} />
        </section>
        {totalPages > 1 && (
          <PaginationWrapper
            currentPagination={currentPagination}
            setCurrentPagination={setCurrentPagination}
            optionsPerPage={itemsPerPage}
            numberOfButtons={totalPages}
          />
        )}
      </div>
    </div>
  );
};
