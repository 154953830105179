import style from "./EditCommission.module.css";
// @ts-ignore
import { CriticalErrorPage, HeaderNavigation } from "@viuti/recursos";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { LoadingPulseIcon } from "@Models/icons";
import ComissionRuleForm from "@Components/ui/organisms/ComissionRuleForm/ComissionRuleForm";
import { useAppDispatch, useAppSelector } from "@Hooks/store";
import {
  setEmployees,
  setProducts,
  setServices,
} from "@ReduxService/states/commissionsConfigSlice";
import { IResponse } from "@Models/interfaces/api";
import { getServices } from "@Adapters/commissions/getServices.adapter";
import { getProducts } from "@Adapters/commissions/getProducts.adapter";
import {
  updateHeaderButtonDisabled,
  updateHeaderButtonHidden,
  updateHeaderButtonLoading,
  updateHeaderButtonText,
  updateHeaderPreviousAction,
  updateHeaderTitle,
} from "@ReduxService/states/headerNavigationSlice";
import { getCommissionRule } from "@Adapters/commissions/getCommissionRule.adapter";
import { initialCommissionFormValues } from "@Components/ui/molecules/CommissionForm/functions/initialValues";
import { getEmployees } from "@Adapters/employees/getEmployees.adapter";

export const EditCommission = () => {
  const { commissionId } = useParams();
  const dispatch = useAppDispatch();
  const commissionsConfigSlice = useAppSelector(
    (state) => state.commissionsConfig,
  );

  const navigate = useNavigate();
  const headerNavigationProps = useAppSelector(
    (state) => state.headerNavigationProps,
  );
  const [initialValues, setInitialValues] = useState(
    initialCommissionFormValues,
  );
  const [values, setValues] = useState(initialCommissionFormValues);
  const [items, setItems] = useState([]);
  const [pageState, setPageState] = useState({
    loading: false,
    error: false,
  });

  useEffect(() => {
    (async () => {
      if (
        commissionsConfigSlice.services.length === 0 &&
        commissionsConfigSlice.products.length === 0 &&
        commissionsConfigSlice.employees.length === 0
      ) {
        setPageState({ ...pageState, loading: true });

        const services: IResponse = await getServices(dispatch);
        const products: IResponse = await getProducts(dispatch);
        const employees: IResponse = await getEmployees(dispatch);

        setPageState({ loading: false, error: false });

        if (
          !services.isSuccess ||
          !products.isSuccess ||
          !employees.isSuccess
        ) {
          setPageState({ loading: false, error: true });
          return;
        }

        dispatch(setServices(services.data));
        dispatch(setProducts(products.data));
        dispatch(setEmployees(employees.data));
      }
    })();

    dispatch(updateHeaderPreviousAction(() => navigate("/")));
    dispatch(updateHeaderButtonText("Guardar"));
    dispatch(updateHeaderButtonLoading(false));
    dispatch(updateHeaderButtonDisabled(true));
  }, []);

  useEffect(() => {
    if (commissionId) {
      (async () => {
        setPageState({ ...pageState, loading: true });
        const initialValues: IResponse = await getCommissionRule(
          dispatch,
          Number(commissionId),
        );
        setPageState({ loading: false, error: false });

        if (!initialValues.isSuccess) {
          setPageState({ loading: false, error: true });
          return;
        }

        dispatch(updateHeaderTitle(initialValues.data.data.name));
        setInitialValues(initialValues.data.data);
        setValues(initialValues.data.data);
        setItems(initialValues.data.items);
      })();
    }
  }, [commissionId]);

  if (pageState.loading)
    return (
      <figure className={style.loading__container}>
        <img src={LoadingPulseIcon} alt="Cargando..." />
      </figure>
    );

  if (pageState.error) return <CriticalErrorPage />;

  return (
    <div id="viuti-front-mainContent">
      <div className={style.container}>
        <HeaderNavigation {...headerNavigationProps} />
        <section className={style.page__content}>
          <ComissionRuleForm
            initialValues={initialValues}
            values={values}
            items={items}
          />
        </section>
      </div>
    </div>
  );
};
