import { IService } from "@Models/interfaces/commissions";
import { AppDispatch } from "@ReduxService/index";
import {
  updateError,
  updateLoading,
} from "@ReduxService/states/commissionsSlice";
import { updateNotification } from "@ReduxService/states/notificationSlice";
import { getServices_service } from "@Services/commissions/getServices";

export const getServices = async (dispatch: AppDispatch) => {
  dispatch(updateLoading(true));
  const response = await getServices_service();
  dispatch(updateLoading(false));

  if (!response.isSuccess) {
    dispatch(updateError(true));
    dispatch(
      updateNotification({
        status: response.status,
        message: response.message,
        toastTimeDuration: 4000,
      }),
    );

    return response;
  }

  const services: IService[] = response.data.flatMap((service: any) => {
    return service.prices.map((price: any) => ({
      id: service.idService,
      name: `${service.nameService}: ${price.namePrice}`,
      price: {
        basePrice: price.price,
        igv: price.igv,
        total: price.totalPrice,
      },
      priceId: price.idPrice,
      isFree: price.free === 1,
      availableEmployees: service.employees.map((employee: any) => ({
        id: employee.idEmployee,
        name: employee.nameEmployee,
        lastName: employee.lastnameEmployee,
      })),
      categories: service.categories.map(
        (category: any) => category.nameCategory,
      ),
    }));
  });

  return {
    ...response,
    data: services,
  };
};
