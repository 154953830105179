import React from "react";
import style from "./styles/Rules.module.css";
import { ICommissionFormValues } from "@Models/interfaces/commissionForm";
// @ts-ignore
import { SelectInput, TextInput } from "@viuti/recursos";

interface Props {
  formValues: ICommissionFormValues;
  handleNestedInputChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    section: string,
  ) => void;
  handleBlur?: (
    e: React.FocusEvent<HTMLInputElement | HTMLSelectElement>,
  ) => void;
  errors: any;
  touched: any;
  disabled: boolean;
}

const Rules = ({
  formValues,
  handleNestedInputChange,
  handleBlur,
  errors,
  touched,
  disabled,
}: Props) => {
  const periodOptions = [
    { value: 1, name: "Semanal" },
    { value: 2, name: "Quincenal" },
    { value: 3, name: "Mensual" },
    { value: 4, name: "Bimestral" },
    { value: 5, name: "Trimestral" },
  ];

  const methodOptions = [
    { value: 1, name: "Comisión por venta" },
    { value: 2, name: "Bono en efectivo" },
  ];

  const modalityOptions = [
    { value: 1, name: "Porcentaje" },
    { value: 2, name: "Monto fijo" },
  ];

  return (
    <>
      <h3>Formula de la comisión</h3>
      <div className={style.container}>
        <div className={style.rules__form}>
          <TextInput
            label="Al alcanzar ventas iguales o mayores a"
            value={formValues.rules.salesThreshold}
            touched={touched["rules.salesThreshold"] || errors.salesThreshold}
            error={errors.rules?.salesThreshold || errors.salesThreshold}
            handleChange={(e) => handleNestedInputChange(e, "rules")}
            handleBlur={handleBlur}
            placeholder={"Cantidad de ventas en soles"}
            inputProps={{ type: "number" }}
            name={"salesThreshold"}
            prefix={formValues.rules.salesThreshold ? "S/." : null}
            disabled={disabled}
          />
          <SelectInput
            label="Método"
            value={formValues.rules.method}
            items={methodOptions}
            handleChange={(e) => handleNestedInputChange(e, "rules")}
            handleBlur={handleBlur}
            name="method"
            touched={touched["rules.method"] || touched.method}
            error={errors.rules?.method || errors.method}
            required
            disabled={disabled}
          />

          {formValues.rules.method === 1 && (
            <SelectInput
              label="Modalidad"
              value={formValues.rules.modality}
              items={modalityOptions}
              handleChange={(e) => handleNestedInputChange(e, "rules")}
              name="modality"
              touched={touched["rules.modality"]}
              error={errors.rules?.modality}
              required
              disabled={disabled}
            />
          )}
          <TextInput
            label="Monto"
            value={formValues.rules.amount}
            touched={touched["rules.amount"] || touched.amount}
            error={errors.rules?.amount || errors.amount}
            handleChange={(e) => handleNestedInputChange(e, "rules")}
            handleBlur={handleBlur}
            placeholder={"0.00"}
            inputProps={{ type: "number" }}
            name={"amount"}
            required
            prefix={
              formValues.rules.method === 1
                ? formValues.rules.modality === 1
                  ? "%"
                  : "S/."
                : "S/."
            }
            disabled={disabled}
          />
        </div>
        <div className={style.conditions__options}>
          <div className={style.checkbox_container}>
            <label className={style.label_container}>
              <input
                type="checkbox"
                name="promotions"
                checked={formValues?.conditions?.promotions || false}
                onChange={(e) => handleNestedInputChange(e, "conditions")}
                disabled={disabled}
              />
              <span className={style.label}>
                Promociones, paquetes o descuentos
              </span>
            </label>
            <p className={style.description}>
              Calcular la comisión en base al precio final (con el descuento
              aplicado) en caso de paquetes y promociones.
            </p>
            {errors["conditions.promotions"] &&
              touched["conditions.promotions"] && (
                <span className={style.error}>
                  {errors["conditions.promotions"]}
                </span>
              )}
          </div>

          <div className={style.checkbox_container}>
            <label className={style.label_container}>
              <input
                type="checkbox"
                name="taxes"
                checked={formValues?.conditions?.taxes || false}
                onChange={(e) => handleNestedInputChange(e, "conditions")}
                disabled={disabled}
              />
              <span className={style.label}>Impuestos</span>
            </label>
            <p className={style.description}>
              Calcula la comisión en base al precio final descontando impuestos.
            </p>
            {errors["conditions.taxes"] && touched["conditions.taxes"] && (
              <span className={style.error}>{errors["conditions.taxes"]}</span>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Rules;
