import style from "./DeleteCommissionModal.module.css";
import { WarningTriangleIcon } from "@Models/icons";
// @ts-ignore
import { Icon } from "@viuti/recursos";

export const DeleteCommissionModal = () => {
  return (
    <div className={style.form_container}>
      <figure>
        <Icon path={WarningTriangleIcon} alt="Simbolo de advertencia" />
      </figure>
      <h3 className={style.warning_message}>
        ¿Estás seguro de que deseas desactivar esta regla de comisión?
        <br />
        Ten en cuenta que esta acción no se puede deshacer.
      </h3>
    </div>
  );
};
