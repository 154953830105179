import { getData, servicesBaseUrl } from "@Services/constServices";

export interface IGetServicesResponse {
  isSuccess: boolean;
  status: number;
  message: string;
  data: any;
}

export const getServices_service = async (): Promise<IGetServicesResponse> => {
  const response = getData(
    `${servicesBaseUrl}/Services/GetServices`,
    "Servicios obtenidos correctamente",
    "Ocurrió un error inesperado al obtener los servicios",
  );

  return response;
};
