import { ICommissionFormValues } from "@Models/interfaces/commissionForm";

export const initialCommissionFormValues: ICommissionFormValues = {
  name: "",
  rules: {
    salesThreshold: "",
    period: "",
    method: "",
    modality: 2,
    amount: "",
    taxIncluded: false,
    conditionPromotion: false,
  },
  teamMembers: [],
  products: [],
  services: [],
  conditions: {
    promotions: false,
    taxes: false,
  },
};
