import { getData, productsBaseUrl } from "@Services/constServices";

export interface IGetServicesResponse {
  isSuccess: boolean;
  status: number;
  message: string;
  data: any;
}

export const getProducts_service = async (): Promise<IGetServicesResponse> => {
  const response = getData(
    `${productsBaseUrl}/Product/GetLoungeProducts`,
    "Productos obtenidos correctamente",
    "Ocurrió un error inesperado al obtener los productos",
  );

  return response;
};
