import { useState, useCallback } from "react";
import { useAppDispatch } from "@Hooks/store";
import {
  orderByName,
  orderByCreationDate,
  showActiveCommissions,
  showInactiveCommissions,
  updateFilteredCommissions,
  orderByEmployee,
} from "@ReduxService/states/commissionsSlice";
import style from "./FilterCommissionModal.module.css";
// @ts-ignore
import { Icon } from "@viuti/recursos";
import { CheckIcon, ValidatedIcon } from "@Models/icons";
import { createdDate, employee, name } from "@Models/const/commissions";

export const FilterCommissionModal = () => {
  const dispatch = useAppDispatch();
  const [sortBy, setSortBy] = useState("");
  const [showActive, setShowActive] = useState(false);
  const [showInactive, setShowInactive] = useState(false);

  const handleShowActiveChange = useCallback(() => {
    const newShowActive = !showActive;
    setShowActive(newShowActive);
    dispatch(showActiveCommissions(newShowActive));
    dispatch(updateFilteredCommissions());
  }, [dispatch, showActive]);

  const handleShowInactiveChange = useCallback(() => {
    const newShowInactive = !showInactive;
    setShowInactive(newShowInactive);
    dispatch(showInactiveCommissions(newShowInactive));
    dispatch(updateFilteredCommissions());
  }, [dispatch, showInactive]);

  const handleSortChange = useCallback(
    (criteria) => {
      setSortBy(criteria);
      switch (criteria) {
        case name:
          dispatch(orderByName());
          break;
        case createdDate:
          dispatch(orderByCreationDate());
          break;
        case employee:
          dispatch(orderByEmployee());
          break;
        default:
          break;
      }
    },
    [dispatch],
  );

  const renderSortButton = (criteria, label) => (
    <button
      className={`${style.orderItem} ${
        sortBy === criteria ? style.cliquedItem : ""
      }`}
      onClick={() => handleSortChange(criteria)}
    >
      {sortBy === criteria ? (
        <Icon path={ValidatedIcon} size={14} color={"#45348E"} />
      ) : (
        <span className={style.circle}></span>
      )}
      <p>{label}</p>
    </button>
  );

  return (
    <div>
      <div className={style.containerFilterOptions}>
        <p className={style.titlesFilter}>Ordenar por</p>
        <div className={style.orderSection}>
          {renderSortButton(name, "Nombre a-z")}
          {renderSortButton(createdDate, "Fecha de creación")}
          {renderSortButton(employee, "Creación de empleado")}
        </div>
        <div className={style.flexItem}>
          <p className={style.titlesFilter}>Ver reglas: </p>
          <button
            className={style.flexItem}
            onClick={(e) => {
              e.stopPropagation();
              handleShowActiveChange();
            }}
          >
            {showActive ? (
              <div className={style.containerChecked}>
                <Icon path={CheckIcon} size={14} color={"#45348E"} />
              </div>
            ) : (
              <span className={style.square}></span>
            )}
            Activas
          </button>

          <button
            className={style.flexItem}
            onClick={(e) => {
              e.stopPropagation();
              handleShowInactiveChange();
            }}
          >
            {showInactive ? (
              <div className={style.containerChecked}>
                <Icon path={CheckIcon} size={12} color={"#45348E"} />
              </div>
            ) : (
              <span className={style.square}></span>
            )}
            Inactivas
          </button>
        </div>
      </div>
    </div>
  );
};
