import React from "react";
import style from "./FormNavigationColumn.module.css";

const FormNavigationColumn = ({
  currentSection,
  setCurrentSection,
  formulaFormRef,
  membersFormRef,
  itemsAppliedFormRef,
}: {
  currentSection: number;
  setCurrentSection: (sectionId: number) => void;
  formulaFormRef: React.RefObject<any>;
  membersFormRef: React.RefObject<any>;
  itemsAppliedFormRef: React.RefObject<any>;
}) => {
  const changeSection = (sectionId: number) => {
    switch (sectionId) {
      case 0:
        formulaFormRef.current?.scrollIntoView({ behavior: "smooth" });
        break;
      case 1:
        membersFormRef.current?.scrollIntoView({ behavior: "smooth" });
        break;
      case 2:
        itemsAppliedFormRef.current?.scrollIntoView({ behavior: "smooth" });
        break;
      default:
        break;
    }
    setCurrentSection(sectionId);
  };

  return (
    <div className={style.navigation__menu__container}>
      <button
        className={`${style.navigation__button} ${
          currentSection === 0 ? style.navigation__button__active : ""
        }`}
        onClick={() => changeSection(0)}
      >
        Formula
      </button>
      {/* <button
        className={`${style.navigation__button} ${
          currentSection === 1 ? style.navigation__button__active : ""
        }`}
        onClick={() => changeSection(1)}
      >
        Equipo
      </button>
      <button
        className={`${style.navigation__button} ${
          currentSection === 2 ? style.navigation__button__active : ""
        }`}
        onClick={() => changeSection(2)}
      >
        Items
      </button> */}
    </div>
  );
};

export default FormNavigationColumn;
