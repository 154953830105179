import { IProduct } from "@Models/interfaces/commissions";
import { AppDispatch } from "@ReduxService/index";
import {
  updateError,
  updateLoading,
} from "@ReduxService/states/commissionsSlice";
import { updateNotification } from "@ReduxService/states/notificationSlice";
import { getProducts_service } from "@Services/commissions/getProducts";
import { setProducts } from "@ReduxService/states/commissionsConfigSlice";

export const getProducts = async (dispatch: AppDispatch) => {
  dispatch(updateLoading(true));
  const response = await getProducts_service();
  dispatch(updateLoading(false));

  if (!response.isSuccess) {
    dispatch(updateError(true));
    dispatch(
      updateNotification({
        status: response.status,
        message: response.message,
        toastTimeDuration: 4000,
      }),
    );

    return response;
  }

  // Transformar respuesta a un arreglo simple de productos evitando duplicados
  const productMap = new Map(); // Mapa para controlar duplicados

  response.data.forEach((product: any) => {
    // Solo agregar el producto si no existe ya en el mapa
    if (!productMap.has(product.productId)) {
      productMap.set(product.productId, {
        id: product.productId,
        name: product.productName,
        code: product.code,
        stock: product.stock,
        price: product.price,
        brand: product.brand.brandName,
        subBrand: product.line.lineName,
        categories: product.category.map(
          (category: any) => category.categoryName,
        ),
      });
    }
  });

  // Convertir el mapa a array
  const products: IProduct[] = Array.from(productMap.values());

  // Actualizar el estado con el nuevo arreglo de productos
  dispatch(setProducts(products));

  return {
    ...response,
    data: products,
  };
};
