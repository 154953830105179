import { getData, employeesBaseUrl } from "@Services/constServices";

export interface IGetServicesResponse {
  isSuccess: boolean;
  status: number;
  message: string;
  data: any;
}

export const getCommissionRule_service = async (
  commissionId: number,
): Promise<IGetServicesResponse> => {
  const response = getData(
    `${employeesBaseUrl}/CommissionEmployee/GetCommmisionEmployeeDetail?commisionId=${commissionId}`,
    "Comisión obtenida correctamente",
    "Ocurrió un error inesperado al obtener la comisión seleccionada",
  );

  return response;
};
