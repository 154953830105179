import {
  closeModal,
  updateModalAcceptCancel,
} from "@ReduxService/states/modalAcceptCancelSlice";
import { deleteCommission } from "@Adapters/commissions/deleteCommission.adapter";
import { AppDispatch } from "@ReduxService/index";
import { DELETE_COMMISSION_MODAL_TYPE } from "@Models/const/modalTypes";

export const openDeleteCommissionModal = (
  e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  id: number,
  dispatch: AppDispatch,
  screenWidth: number,
) => {
  e.stopPropagation();
  dispatch(
    updateModalAcceptCancel({
      title: "Desactivar regla de comisión",
      visibility: {
        isVisible: true,
      },
      modalType: DELETE_COMMISSION_MODAL_TYPE,
      showButtons: {
        showButtonOne: screenWidth > 500,
        showButtonTwo: true,
        showButtonClose: screenWidth <= 500,
      },
      buttonOne: {
        textButtonOne: "Cancelar",
        actionButtonOne: () => dispatch(closeModal()),
        isDisabled: false,
        isLoading: false,
      },
      buttonTwo: {
        textButtonTwo: "Desactivar",
        actionButtonTwo: () => deleteCommission(id, dispatch),
        isDisabled: false,
        isLoading: false,
      },
      actionButtonClose: () => dispatch(closeModal()),
    }),
  );
};
