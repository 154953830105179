import { changeLoadingButtonTwo } from "@ReduxService/states/modalAcceptCancelSlice";
import { updateNotification } from "@ReduxService/states/notificationSlice";
import { AppDispatch } from "@ReduxService/index";
import { ICommissionFormValues } from "@Models/interfaces/commissionForm";
import { createCommission_service } from "@Services/commissions/createCommission";
import {
  updateHeaderButtonDisabled,
  updateHeaderButtonLoading,
} from "@ReduxService/states/headerNavigationSlice";

export const createCommission = async (
  dispatch: AppDispatch,
  formValues: ICommissionFormValues,
) => {
  const payload = {
    nameComision: formValues.name.trim() || "string",
    goal: Number(formValues.rules.salesThreshold) || 0,
    idPeriod: Number(formValues.rules.period) || 0,
    idMethod: Number(formValues.rules.method) || 0,
    idTypeAmount: Number(formValues.rules.modality) || 0,
    amount: Number(formValues.rules.amount) || 0,
    idMembers: formValues.teamMembers.map((member) => Number(member.id)) || [0],
    idPriceService: formValues.services?.map(Number) || [0],
    idProducts: formValues.products?.map(Number) || [0],
    conditionTax: Boolean(formValues.conditions.taxes),
    conditionPromotion: Boolean(formValues.conditions.promotions),
  };

  dispatch(updateHeaderButtonLoading(true));
  dispatch(updateHeaderButtonDisabled(true));
  const response = await createCommission_service(payload);

  if (response.isSuccess) {
    window.location.href = "/comisiones";
  }

  dispatch(updateHeaderButtonLoading(false));
  dispatch(updateHeaderButtonDisabled(false));
  dispatch(
    updateNotification({
      message: response.message,
      status: response.status,
      toastTimeDuration: 4000,
    }),
  );
};
