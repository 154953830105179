import { ICommissionFormValues } from "@Models/interfaces/commissionForm";
import { AppDispatch } from "@ReduxService/index";
import {
  updateError,
  updateLoading,
} from "@ReduxService/states/commissionsSlice";
import { updateNotification } from "@ReduxService/states/notificationSlice";
import { getCommissionRule_service } from "@Services/commissions/getCommissionRule";

export const getCommissionRule = async (
  dispatch: AppDispatch,
  commissionId: number,
) => {
  dispatch(updateLoading(true));
  const response = await getCommissionRule_service(commissionId);
  dispatch(updateLoading(false));

  if (!response.isSuccess) {
    dispatch(updateError(true));
    dispatch(
      updateNotification({
        status: response.status,
        message: response.message,
        toastTimeDuration: 4000,
      }),
    );

    return response;
  }

  const commission = response.data.commission;

  const formattedData: ICommissionFormValues = {
    name: response.data.commisionName,
    rules: {
      salesThreshold: response.data.goal,
      period: 1,
      method: response.data.methodId,
      modality: response.data.typeDiscountId,
      amount: response.data.amount,
      taxIncluded: response.data.condicionTax,
      conditionPromotion: response.data.condicionPromotion,
    },
    teamMembers: response.data.commisionEmployee.map((employee) => {
      return {
        id: employee.employeeId,
        name: employee.employeeName,
      };
    }),
    services: response.data.commisionItems
      .filter((item) => item.getCommisionDetailServices)
      .map((item) => item.getCommisionDetailServices.priceServiceId),
    products: response.data.commisionItems
      .filter((item) => item.getCommsionDetailProduct)
      .map((item) => item.getCommsionDetailProduct.productId),
    conditions: {
      promotions: response.data.condicionPromotion,
      taxes: response.data.condicionTax,
    },
  };
  const items = response.data.commisionItems;
  return {
    ...response,
    data: {
      data: formattedData,
      items,
    },
  };
};
