import React, { useState, useEffect } from "react";
import style from "./styles/AppliedProducts.module.css";
// @ts-ignore
import { SearchAndFilter, ModalAcceptCancel, Icon } from "@viuti/recursos";
import { useAppSelector } from "@Hooks/store";
import { IProduct } from "@Models/interfaces/commissions";
import { CodeBarIcon } from "@Models/icons";

interface Props {
  appliedItems: number[];
  handleAddItem: (id: number) => void;
  handleRemoveItem: (id: number) => void;
  handleNestedInputChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    section: string,
  ) => void;
  errors: any;
  touched: any;
  disabled: boolean;
}

const AppliedProducts: React.FC<Props> = ({
  appliedItems,
  handleAddItem,
  handleRemoveItem,
  errors,
  touched,
  disabled,
}) => {
  const products: IProduct[] = useAppSelector(
    (state) => state.commissionsConfig.products,
  );

  const [searchInput, setSearchInput] = useState("");
  const [filteredProducts, setFilteredProducts] = useState<IProduct[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const filtered = products.filter((product) =>
      product.name.toLowerCase().includes(searchInput.toLowerCase()),
    );
    setFilteredProducts(filtered);
  }, [searchInput, products]);

  useEffect(() => {
    const allSelected =
      products.length > 0 &&
      products.every((product) => appliedItems.includes(product.id));
    setSelectAll(allSelected);
  }, [appliedItems, products]);

  const handleAction = (isChecked: boolean, itemId: number) => {
    if (isChecked) {
      handleRemoveItem(itemId);
    } else {
      handleAddItem(itemId);
    }
  };

  const handleSelectAll = (isChecked: boolean) => {
    filteredProducts.forEach((product) => {
      handleAction(isChecked, product.id);
    });
    setSelectAll(!isChecked);
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const modalProps = {
    title: "Seleccionar productos",
    children: (
      <div className={style.modal}>
        <div className={style.container}>
          <SearchAndFilter
            placeholder="Buscar por nombre de producto"
            searchInput={searchInput}
            setSearchInput={setSearchInput}
            isFilterAvailable={false}
          />
          <div className={style.items__container}>
            <div className={style.header}>
              <input
                type="checkbox"
                id="selectAllCommissionProducts"
                name="selectAllCommissionProducts"
                checked={selectAll}
                onChange={(e) => handleSelectAll(!e.target.checked)}
                className={style.selectAllCheckbox}
                disabled={disabled}
              />
              <label
                htmlFor="selectAllCommissionProducts"
                className={style.selectAllLabel}
              >
                {selectAll ? "Deseleccionar todos" : "Seleccionar todos"}
              </label>
            </div>
            <div className={style.products__list}>
              {filteredProducts.map((product) => {
                const isChecked = appliedItems.includes(product.id);
                return (
                  <label key={product.id} className={style.item}>
                    <input
                      type="checkbox"
                      id={product.id.toString()}
                      value={product.id}
                      checked={isChecked}
                      name="appliedItems"
                      onChange={(e) => handleAction(isChecked, product.id)}
                      className={style.item__checkbox}
                      disabled={disabled}
                    />
                    <div className={style.item__container}>
                      <div className={style.item__info}>
                        <span className={style.item__name}>{product.name}</span>
                      </div>
                    </div>
                  </label>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    ),
    showButtons: {
      showButtonOne: false,
      showButtonTwo: false,
      showButtonClose: true,
    },
    actionButtonClose: toggleModal,
  };

  return (
    <>
      {/* <p onClick={toggleModal}>
        Click aquí para seleccionar los productos a los que se aplicará la regla
        de comisión.
      </p> */}
      <div className={style.products__container}>
        <label className={`${style.label} ${style.title}`} htmlFor="productos">
          <div className={style.icon}>
            <Icon path={CodeBarIcon} size={14} color="#45348e" alt="Editar" />
          </div>
          Productos
        </label>
        <p className={style.description}>
          Selecciona los productos que deseas incluir en la regla de comisión
          <span className={style.button_open_products} onClick={toggleModal}>
            dando clic aquí
          </span>
          .
        </p>
        <p>
          <strong>{appliedItems.length} productos seleccionados</strong>
        </p>
      </div>
      {isModalOpen && <ModalAcceptCancel {...modalProps} />}
    </>
  );
};

export default AppliedProducts;
