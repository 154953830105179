import { employeesBaseUrl, deleteData } from "@Services/constServices";

export const deleteCommission_service = async (id) => {
  const response = deleteData(
    `${employeesBaseUrl}/CommissionEmployee/DeleteCommisionEmployee?commisionId=${id}`,
    "Comisión eliminada correctamente",
    "Error al eliminar la comisión. Intente de nuevo.",
  );

  return response;
};
