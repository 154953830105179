import { ICommission } from "@Models/interfaces/commissions";
import { AppDispatch } from "@ReduxService/index";
import {
  updateCommissions,
  updateCurrentPage,
  updateError,
  updateLoading,
  updateTotalPages,
} from "@ReduxService/states/commissionsSlice";
import { updateNotification } from "@ReduxService/states/notificationSlice";
import { getCommissions_service } from "@Services/commissions/getCommissions";

export const getCommissions = async (
  dispatch: AppDispatch,
  navigateToPage: number,
) => {
  dispatch(updateLoading(true));
  const response = await getCommissions_service(navigateToPage);
  dispatch(updateLoading(false));

  if (!response.isSuccess) {
    dispatch(updateError(true));
    dispatch(
      updateNotification({
        status: response.status,
        message: response.message,
        toastTimeDuration: 4000,
      }),
    );

    return response;
  }

  const commissions: ICommission[] = response.data.commissions.map(
    (commission: any) => {
      return {
        id: commission.id,
        name: commission.name,
        isActive: commission.isActive === 1,
        createdDate: commission.createdDate,
        employee: commission.employee,
      };
    },
  );

  dispatch(updateCommissions(commissions));
  dispatch(updateCurrentPage(response.data.currentPage));
  dispatch(updateTotalPages(response.data.totalPages));

  return {
    ...response,
    data: commissions,
  };
};
