import React, { useRef, useState } from "react";
import FormNavigationColumn from "@Components/ui/molecules/FormNavigationColumn/FormNavigationColumn";
import CommissionForm from "@Components/ui/molecules/CommissionForm/CommissionForm";
import style from "./ComissionRuleForm.module.css";

import { initialCommissionFormValues } from "@Components/ui/molecules/CommissionForm/functions/initialValues";
import { ICommissionFormValues } from "@Models/interfaces/commissionForm";

interface ComissionRuleFormProps {
  initialValues?: ICommissionFormValues;
  values?: ICommissionFormValues;
  items?: any[];
}

const ComissionRuleForm = ({
  initialValues = initialCommissionFormValues,
  values,
  items,
}: ComissionRuleFormProps) => {
  const [currentSection, setCurrentSection] = useState(0);
  const formulaFormRef = useRef(null);
  const membersFormRef = useRef(null);
  const itemsAppliedFormRef = useRef(null);

  return (
    <div className={style.container}>
      <FormNavigationColumn
        currentSection={currentSection}
        setCurrentSection={setCurrentSection}
        formulaFormRef={formulaFormRef}
        membersFormRef={membersFormRef}
        itemsAppliedFormRef={itemsAppliedFormRef}
      />
      <CommissionForm
        formulaFormRef={formulaFormRef}
        membersFormRef={membersFormRef}
        itemsAppliedFormRef={itemsAppliedFormRef}
        initialValues={initialValues}
        values={values}
        items={items}
      />
    </div>
  );
};

export default ComissionRuleForm;
