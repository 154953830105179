import React, { useState, useEffect } from "react";
import style from "./styles/AppliedServices.module.css";
// @ts-ignore
import { SearchAndFilter, ModalAcceptCancel, Icon } from "@viuti/recursos";
import { useAppSelector } from "@Hooks/store";
import { IService } from "@Models/interfaces/commissions";
import { CodeBarIcon, ServiceIcon } from "@Models/icons";

interface Props {
  appliedItems: number[];
  handleAddItem: (id: number) => void;
  handleRemoveItem: (index: number) => void;
  handleNestedInputChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    section: string,
  ) => void;
  errors: any;
  touched: any;
  disabled: boolean;
}

const AppliedServices: React.FC<Props> = ({
  appliedItems,
  handleAddItem,
  handleRemoveItem,
  errors,
  touched,
  disabled,
}) => {
  const services: IService[] = useAppSelector(
    (state) => state.commissionsConfig.services,
  );

  const [searchInput, setSearchInput] = useState("");
  const [filteredServices, setFilteredServices] =
    useState<IService[]>(services);

  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const result = services.filter((service) =>
      service.name.toLowerCase().includes(searchInput.toLowerCase()),
    );
    setFilteredServices(result);
  }, [searchInput, services]);

  const handleAction = (isChecked: boolean, itemId: number) => {
    if (isChecked) {
      handleRemoveItem(itemId);
    } else {
      handleAddItem(itemId);
    }
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const areAllServicesSelected = () => {
    return filteredServices.every((service) =>
      appliedItems.includes(service.priceId),
    );
  };

  const modalProps = {
    title: "Seleccionar servicios",
    children: (
      <div className={style.modal}>
        <div className={style.container}>
          <SearchAndFilter
            placeholder="Buscar por nombre de servicio"
            searchInput={searchInput}
            setSearchInput={setSearchInput}
            isFilterAvailable={false}
          />
          <div className={style.items__container}>
            <div className={style.header}>
              <input
                type="checkbox"
                id="selectAllCommissionServices"
                name="selectAllCommissionServices"
                checked={areAllServicesSelected()}
                onChange={(e) => {
                  const isChecked = !e.target.checked;
                  filteredServices.forEach((service) => {
                    handleAction(isChecked, service.priceId);
                  });
                }}
                className={style.selectAllCheckbox}
                disabled={disabled}
              />
              <label
                htmlFor="selectAllCommissionServices"
                className={style.selectAllLabel}
              >
                {areAllServicesSelected()
                  ? "Deseleccionar todos"
                  : "Seleccionar todos"}
              </label>
            </div>
            {filteredServices.map((service) => {
              const isChecked = appliedItems.some(
                (item) => item === service.priceId,
              );
              return (
                <label key={service.id} className={style.item}>
                  <input
                    type="checkbox"
                    id={service.id.toString()}
                    value={service.id}
                    checked={isChecked}
                    name="appliedItems"
                    onChange={(e) => handleAction(isChecked, service.priceId)}
                    className={style.item__checkbox}
                    disabled={disabled}
                  />
                  <div className={style.item__container}>
                    <span className={style.item__name}>{service.name}</span>
                  </div>
                </label>
              );
            })}
          </div>
        </div>
      </div>
    ),
    showButtons: {
      showButtonOne: false,
      showButtonTwo: false,
      showButtonClose: true,
    },
    actionButtonClose: toggleModal,
  };

  return (
    <>
      {/* <p onClick={toggleModal}>
        Click aquí para seleccionar los productos a los que se aplicará la regla
        de comisión.
      </p> */}
      <div className={style.products__container}>
        <label className={`${style.label} ${style.title}`} htmlFor="productos">
          <div className={style.icon}>
            <Icon path={ServiceIcon} size={14} color="#45348e" alt="Editar" />
          </div>
          Servicios
        </label>
        <p className={style.description}>
          Selecciona los servicios que deseas incluir en la regla de comisión
          <span className={style.button_open_products} onClick={toggleModal}>
            dando clic aquí
          </span>
          .
        </p>
        <p>
          <strong>{appliedItems.length} servicios seleccionados</strong>
        </p>
      </div>
      {isModalOpen && <ModalAcceptCancel {...modalProps} />}
    </>
  );
};

export default AppliedServices;
