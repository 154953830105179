import { employeesBaseUrl, getData } from "@Services/constServices";

import { ICommission } from "@Models/interfaces/commissions";

export interface IGetCommissionsResponse {
  isSuccess: boolean;
  status: number;
  message: string;
  data: {
    commissions: ICommission[];
    currentPage: number;
    totalPages: number;
  };
}

export const getCommissions_service = async (
  navigateToPage: number,
): Promise<IGetCommissionsResponse> => {
  const response = getData(
    `${employeesBaseUrl}/CommissionEmployee/GetCommmisionEmployee?page=${navigateToPage}`,
    "Comisiones obtenidas correctamente",
    "Error al obtener las comisiones. Intente de nuevo.",
  );

  return response;
};
