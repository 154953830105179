import { format } from "date-fns";

export const convertDateFormat = (date: string): string => {
  const tempDate = new Date(date);

  // Format the date into the new format
  const formattedDateString = format(tempDate, "dd/MM/yyyy");

  return formattedDateString;
};
