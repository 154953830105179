import { IEmployee, IProduct, IService } from "@Models/interfaces/commissions";
import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

export interface ICommissionsConfigState {
  services: IService[];
  products: IProduct[];
  employees: IEmployee[];
}

const initialState: ICommissionsConfigState = {
  services: [],
  products: [],
  employees: [],
};

const commissionsConfigSlice = createSlice({
  name: "commissionsConfig",
  initialState,
  reducers: {
    setServices(state, action: PayloadAction<IService[]>) {
      state.services = action.payload;
    },
    setProducts(state, action: PayloadAction<IProduct[]>) {
      state.products = action.payload;
    },
    setEmployees(state, action: PayloadAction<IEmployee[]>) {
      state.employees = action.payload;
    },
  },
});

export default commissionsConfigSlice.reducer;
export const { setServices, setProducts, setEmployees } =
  commissionsConfigSlice.actions;
