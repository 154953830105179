import { ICommissionFormValues } from "@Models/interfaces/commissionForm";

export const validate = (values: ICommissionFormValues) => {
  const errors: any = {};

  if (!values.name) {
    errors.name = "Campo requerido";
  }

  if (!values.rules.period) {
    errors.rules = errors.rules || {};
    errors.rules.period = "Campo requerido";
  }

  if (!values.rules.method) {
    errors.rules = errors.rules || {};
    errors.rules.method = "Campo requerido";
  }

  if (values.rules.method === 1 && !values.rules.modality) {
    errors.rules = errors.rules || {};
    errors.rules.modality = "Campo requerido";
  }

  if (!values.rules.amount) {
    errors.rules = errors.rules || {};
    errors.rules.amount = "Campo requerido";
  } else if (values.rules.method === 1) {
    if (values.rules.modality === 1) {
      if (
        Number(values.rules.amount) > 100 ||
        Number(values.rules.amount) <= 0
      ) {
        errors.rules = {
          ...errors.rules,
          amount: "El porcentaje debe estar entre 0 y 100",
        };
      }
    } else if (values.rules.modality === 2) {
      if (Number(values.rules.amount) <= 0) {
        errors.rules = {
          ...errors.rules,
          amount: "El monto debe ser mayor a 0",
        };
      }
    }
  }

  if (values.teamMembers.length === 0) {
    errors.teamMembers = "Se requiere seleccionar al menos un miembro";
  }

  return errors;
};
