//@ts-ignore
import { SearchWithCards } from "@viuti/recursos";
import { useState } from "react";

const SearchWithCardsBuilder = ({
  label,
  placeholder,
  dataToFilter,
  onChange,
  name,
  values = [],
  hasIntro = true,
  style = {},
  disabled = false,
}) => {
  const [search, setSearch] = useState("");

  const textInputProps = {
    label: label,
    required: true,
    placeholder: placeholder,
    value: search,
    handleChange: (e) => {
      setSearch(e.target.value);
    },
  };

  const handleCardsChange = (e) => {
    onChange(e);
  };

  return (
    <div style={{ ...style }}>
      <SearchWithCards
        textInputProps={textInputProps}
        dataToFiltered={dataToFilter}
        cards={values}
        setCards={handleCardsChange}
        hasIntro={hasIntro}
        disabled={disabled}
      />
    </div>
  );
};

export default SearchWithCardsBuilder;
