import { configureStore } from "@reduxjs/toolkit";
import commissionsReducer from "./states/commissionsSlice";
import commissionsConfigSlice from "./states/commissionsConfigSlice";
import headerNavigationReducer from "./states/headerNavigationSlice";
import modalAcceptCancelReducer from "./states/modalAcceptCancelSlice";
import notificationReducer from "./states/notificationSlice";

export const store = configureStore({
  reducer: {
    commissions: commissionsReducer,
    commissionsConfig: commissionsConfigSlice,
    headerNavigationProps: headerNavigationReducer,
    modalConfig: modalAcceptCancelReducer,
    notificationConfig: notificationReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppSelector = (state: RootState) => RootState;
