// src/components/formSections/TeamMembers.tsx
import React from "react";
import SearchWithCardsBuilder from "../../atoms/SearchWithCardsBuilder/SearchWithCardsBuilder";
import { useAppSelector } from "@Hooks/store";
import { IEmployee } from "@Models/interfaces/commissions";
import styles from "../CommissionForm.module.css";

interface Props {
  teamMembers: { id: number; name: string }[];
  handleTeamMemberChange: (newMembers: { id: number; name: string }[]) => void;
  errors: any;
  touched: any;
  disabled: boolean;
}

const TeamMembers: React.FC<Props> = ({
  teamMembers,
  handleTeamMemberChange,
  errors,
  touched,
  disabled,
}) => {
  const employees: IEmployee[] = useAppSelector(
    (state) => state.commissionsConfig.employees,
  );

  const formattedData = employees.map((employee) => {
    return {
      id: employee.id,
      name: `${employee.name} ${employee.lastName}`,
    };
  });

  const displayedMembers = teamMembers;

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      handleTeamMemberChange(formattedData);
    } else {
      handleTeamMemberChange([]);
    }
  };

  return (
    <div className={styles.teamMembersContainer}>
      <div className={styles.searchWithCardsContainer}>
        <h3 className={styles.title}>Miembros asignados</h3>
        <div className={styles.checkboxContainer}>
          <input
            type="checkbox"
            id="selectAll"
            checked={teamMembers.length === formattedData.length}
            onChange={handleSelectAll}
            className={styles.checkbox}
            disabled={disabled}
          />
          <label htmlFor="selectAll" className={styles.checkboxLabel}>
            Seleccionar todos
          </label>
        </div>
      </div>
      <SearchWithCardsBuilder
        label="Miembros del equipo"
        placeholder="Buscar y seleccionar por nombre de empleado"
        dataToFilter={formattedData}
        onChange={handleTeamMemberChange}
        name="teamMembers"
        values={displayedMembers}
        hasIntro={false}
        disabled={disabled}
      />
    </div>
  );
};

export default TeamMembers;
