import { ICommission } from "@Models/interfaces/commissions";
import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

export interface ICommissionsState {
  commissions: ICommission[];
  currentPage: number;
  totalPages: number;

  filteredCommissions: ICommission[];
  isActiveFilter: boolean;
  isInactiveFilter: boolean;
  loading: boolean;
  error: boolean;
}

const initialState: ICommissionsState = {
  commissions: [],
  currentPage: 1,
  totalPages: 1,
  filteredCommissions: [],
  isActiveFilter: false,
  isInactiveFilter: false,
  loading: false,
  error: false,
};

const commissionsSlice = createSlice({
  name: "commissionsConfig",
  initialState,
  reducers: {
    disableCommission(state, action: PayloadAction<number>) {
      state.commissions.find(
        (commission) => commission.id === action.payload,
      ).isActive = false;
      state.filteredCommissions.find(
        (commission) => commission.id === action.payload,
      ).isActive = false;
    },
    updateCommissions(state, action: PayloadAction<ICommission[]>) {
      state.commissions = action.payload;
      state.filteredCommissions = action.payload;
    },
    updateCurrentPage(state, action: PayloadAction<number>) {
      state.currentPage = action.payload;
    },
    updateTotalPages(state, action: PayloadAction<number>) {
      state.totalPages = action.payload;
    },

    orderByName(state) {
      state.filteredCommissions = state.filteredCommissions.sort((a, b) =>
        a.name.localeCompare(b.name),
      );
    },
    orderByCreationDate(state) {
      state.filteredCommissions = state.filteredCommissions.sort((a, b) => {
        const dateA = new Date(a.createdDate);
        const dateB = new Date(b.createdDate);
        return dateB.getTime() - dateA.getTime();
      });
    },
    orderByEmployee(state) {
      state.filteredCommissions = state.filteredCommissions.sort((a, b) =>
        a.employee.localeCompare(b.employee),
      );
    },
    showActiveCommissions(state, action: PayloadAction<boolean>) {
      state.isActiveFilter = action.payload;
    },
    showInactiveCommissions(state, action: PayloadAction<boolean>) {
      state.isInactiveFilter = action.payload;
    },
    updateFilteredCommissions(state) {
      if (
        (state.isActiveFilter && state.isInactiveFilter) ||
        (!state.isActiveFilter && !state.isInactiveFilter)
      ) {
        state.filteredCommissions = state.commissions;
      } else if (state.isActiveFilter) {
        state.filteredCommissions = state.commissions.filter(
          (commission) => commission.isActive === true,
        );
      } else if (state.isInactiveFilter) {
        state.filteredCommissions = state.commissions.filter(
          (commission) => commission.isActive === false,
        );
      }
    },
    clearFilters(state) {
      state.isActiveFilter = false;
      state.isInactiveFilter = false;
      state.filteredCommissions = state.commissions.filter(
        (commission) => commission.isActive === true,
      );
    },
    updateLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    updateError(state, action: PayloadAction<boolean>) {
      state.error = action.payload;
    },
  },
});

export default commissionsSlice.reducer;
export const {
  disableCommission,
  updateCommissions,
  updateCurrentPage,
  updateTotalPages,
  orderByName,
  orderByCreationDate,
  orderByEmployee,
  showActiveCommissions,
  showInactiveCommissions,
  clearFilters,
  updateFilteredCommissions,
  updateLoading,
  updateError,
} = commissionsSlice.actions;
