import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

export interface Notification {
  message: string;
  status: 0 | 200 | 400 | 404 | 500 | 501 | 502 | string;
  toastTimeDuration?: number | "INFINITE";
}

export const notificationSlice = createSlice({
  name: "notificationConfig",
  initialState: {
    message: "",
    status: 0,
    toastTimeDuration: 4000,
  },
  reducers: {
    updateNotification(
      state,
      action: PayloadAction<{
        message: string;
        status: number | string;
        toastTimeDuration: number | "INFINITE";
      }>,
    ) {
      state.message = action.payload.message;
      state.status = action.payload.status as number;
      state.toastTimeDuration = action.payload.toastTimeDuration as number;
    },
    clearNotification: (state) => {
      state.message = "";
      state.status = 0;
      state.toastTimeDuration = 4000;
    },
  },
});

export default notificationSlice.reducer;
export const { updateNotification, clearNotification } =
  notificationSlice.actions;
