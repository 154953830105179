import React, { useState, useEffect } from "react";
import style from "./CommissionForm.module.css";
import { ICommissionFormValues } from "@Models/interfaces/commissionForm";
import Rules from "./components/Rules";
import TeamMembers from "./components/TeamMembers";
import AppliedServices from "./components/AppliedServices";
import { validate } from "./functions/validation";
// @ts-ignore
import { TextInput } from "@viuti/recursos";
import { capitalizeFirstLetter } from "@Utilities/CapitalizeWords";
import {
  updateHeaderButtonAction,
  updateHeaderButtonDisabled,
  updateHeaderButtonHidden,
} from "@ReduxService/states/headerNavigationSlice";
import { useAppDispatch, useAppSelector } from "@Hooks/store";
import { useParams } from "react-router-dom";
import AppliedProducts from "./components/AppliedProducts";
import { createCommission } from "@Adapters/commissions/createCommission.adapter";
import { updateCommission } from "@Adapters/commissions/updateCommission.adapter";

interface ICommissionForm {
  formulaFormRef: React.RefObject<HTMLElement>;
  membersFormRef: React.RefObject<HTMLElement>;
  itemsAppliedFormRef: React.RefObject<HTMLElement>;
  initialValues: ICommissionFormValues;
  values?: ICommissionFormValues;
  items?: any[];
}

const CommissionForm: React.FC<ICommissionForm> = ({
  formulaFormRef,
  membersFormRef,
  itemsAppliedFormRef,
  initialValues,
  values,
  items,
}) => {
  const { commissionId } = useParams();

  const dispatch = useAppDispatch();
  const [formValues, setFormValues] =
    useState<ICommissionFormValues>(initialValues);
  const [initialFormValues, setInitialFormValues] = useState(initialValues);
  const [touched, setTouched] = useState<{ [key: string]: boolean }>({});
  const [errors, setErrors] = useState<{ [key: string]: any }>({});
  const [newServices, setNewServices] = useState<number[]>([]);
  const [removedServices, setRemovedServices] = useState<number[]>([]);
  const [newProducts, setNewProducts] = useState<number[]>([]);
  const [removedProducts, setRemovedProducts] = useState<number[]>([]);
  const [newTeamMembers, setNewTeamMembers] = useState<
    { id: number; name: string }[]
  >([]);
  const [removedTeamMembers, setRemovedTeamMembers] = useState<
    { id: number; name: string }[]
  >([]);
  const commissionsConfigSlice = useAppSelector(
    (state) => state.commissionsConfig,
  );

  const isEdit = commissionId !== undefined;

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const { name, value, type } = e.target;
    const fieldValue =
      type === "checkbox" ? (e.target as HTMLInputElement).checked : value;

    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: fieldValue,
    }));

    if (name === "name" && value.trim() !== "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        name: undefined,
      }));
    }

    setTouched((prevTouched) => ({
      ...prevTouched,
      [name]: true,
    }));

    const validationErrors = validate({
      ...formValues,
      [name]: fieldValue,
    });
    setErrors(validationErrors);
  };

  const handleNestedInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    section: string,
  ) => {
    const { name, value, type } = e.target;
    const fieldValue =
      type === "checkbox" ? (e.target as HTMLInputElement).checked : value;

    setFormValues((prevValues) => ({
      ...prevValues,
      [section]: {
        ...prevValues[section],
        [name]: fieldValue,
      },
    }));

    setTouched((prevTouched) => ({
      ...prevTouched,
      [`${section}.${name}`]: true,
    }));

    const validationErrors = validate({
      ...formValues,
      [section]: {
        ...formValues[section],
        [name]: fieldValue,
      },
    });
    setErrors(validationErrors);
  };

  const handleAddItem = (id: number, type: "service" | "product") => {
    if (type === "product") {
      setFormValues((prevValues) => ({
        ...prevValues,
        products: [...prevValues.products, id],
      }));
      if (!initialFormValues.products.includes(id)) {
        setNewProducts((prev) => [...prev, id]);
      }
      setRemovedProducts((prev) => prev.filter((item) => item !== id));
    } else {
      setFormValues((prevValues) => ({
        ...prevValues,
        services: [...prevValues.services, id],
      }));
      if (!initialFormValues.services.includes(id)) {
        setNewServices((prev) => [...prev, id]);
      }
      setRemovedServices((prev) => prev.filter((item) => item !== id));
    }
  };

  const handleRemoveItem = (id: number, type: "service" | "product") => {
    if (type === "product") {
      setFormValues((prevValues) => ({
        ...prevValues,
        products: prevValues.products.filter((item) => item !== id),
      }));
      if (initialFormValues.products.includes(id)) {
        setRemovedProducts((prev) => [...prev, id]);
      }
      setNewProducts((prev) => prev.filter((item) => item !== id));
    } else {
      setFormValues((prevValues) => ({
        ...prevValues,
        services: prevValues.services.filter((item) => item !== id),
      }));
      if (initialFormValues.services.includes(id)) {
        setRemovedServices((prev) => [...prev, id]);
      }
      setNewServices((prev) => prev.filter((item) => item !== id));
    }
  };

  const handleTeamMemberChange = (
    newMembers: { id: number; name: string }[],
  ) => {
    const removed = initialFormValues.teamMembers.filter(
      (member) => !newMembers.some((newMember) => newMember.id === member.id),
    );
    const added = newMembers.filter(
      (newMember) =>
        !initialFormValues.teamMembers.some(
          (member) => member.id === newMember.id,
        ),
    );

    setFormValues((prevValues) => ({
      ...prevValues,
      teamMembers: newMembers,
    }));

    setNewTeamMembers(added);
    setRemovedTeamMembers(removed);
  };

  const handleSubmit = () => {
    if (commissionId) {
      const updatedLists = {
        services: {
          news: newServices,
          removed: removedServices,
        },
        products: {
          news: newProducts,
          removed: removedProducts,
        },
        teamMembers: {
          news: newTeamMembers.map((teamMember) => teamMember.id),
          removed: removedTeamMembers.map((teamMember) => teamMember.id),
        },
      };
      updateCommission(
        dispatch,
        Number(commissionId),
        formValues,
        initialFormValues,
        updatedLists,
        setInitialFormValues,
        items,
      );
    } else {
      createCommission(dispatch, formValues);
    }
  };

  const handleBlur = (
    e: React.FocusEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    setTouched((prevTouched) => ({
      ...prevTouched,
      [e.target.name]: true,
    }));

    if (
      e.target.name === "name" &&
      (!formValues.name || formValues.name.trim() === "")
    ) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        name: "El nombre es requerido",
      }));
    }
    if (
      e.target.name === "method" &&
      (!formValues.rules.method || Number(formValues.rules.method) === 0)
    ) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        method: "Este campo es requerido",
      }));
    }
    if (
      e.target.name === "amount" &&
      (!formValues.rules.amount || Number(formValues.rules.amount) === 0)
    ) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        amount: "Este campo es requerido",
      }));
    }
  };

  // Función auxiliar para normalizar valores antes de comparar
  const normalizeValue = (value: any, key?: string): any => {
    // Si es el campo name, aplicamos trim
    if (key === "name" && typeof value === "string") {
      return value.trim();
    }
    // Si es una cadena que representa un número, convertirla a número
    if (typeof value === "string" && !isNaN(Number(value))) {
      return Number(value);
    }
    return value;
  };

  const areEqual = (a: any, b: any, key?: string): boolean => {
    // Normalizar valores antes de comparar
    const normalizedA = normalizeValue(a, key);
    const normalizedB = normalizeValue(b, key);

    // Si son arrays, comparamos su contenido ordenado
    if (Array.isArray(normalizedA) && Array.isArray(normalizedB)) {
      if (normalizedA.length !== normalizedB.length) return false;

      // Si es un array de objetos (como teamMembers)
      if (typeof normalizedA[0] === "object") {
        const sortedA = [...normalizedA].sort((x, y) => x.id - y.id);
        const sortedB = [...normalizedB].sort((x, y) => x.id - y.id);
        return JSON.stringify(sortedA) === JSON.stringify(sortedB);
      }

      // Si es un array de primitivos (como products o services)
      const sortedA = [...normalizedA].sort();
      const sortedB = [...normalizedB].sort();
      return JSON.stringify(sortedA) === JSON.stringify(sortedB);
    }

    // Si son objetos, hacemos una comparación recursiva
    if (
      typeof normalizedA === "object" &&
      normalizedA !== null &&
      typeof normalizedB === "object" &&
      normalizedB !== null
    ) {
      const keysA = Object.keys(normalizedA);
      const keysB = Object.keys(normalizedB);

      if (keysA.length !== keysB.length) return false;

      return keysA.every((key) =>
        areEqual(normalizedA[key], normalizedB[key], key),
      );
    }

    // Para valores primitivos, comparación directa
    return normalizedA === normalizedB;
  };

  const hasFormChanged = () => {
    return !areEqual(formValues, initialFormValues);
  };

  // Modificar el useEffect que controla el estado del botón
  useEffect(() => {
    const isFormValid = () => {
      const { name, rules, products, services, teamMembers } = formValues;

      const areRulesFilled =
        rules.method !== "" && rules.amount !== "" && Number(rules.amount) > 0;

      const hasItems = products.length > 0 || services.length > 0;
      const hasName = name && name.trim() !== "";
      const hasTeamMembers = teamMembers.length > 0;

      return hasName && areRulesFilled && hasItems && hasTeamMembers;
    };

    // Agregar la validación de cambios en el formulario
    const formHasChanges = hasFormChanged();
    const isSubmitDisabled = !isFormValid() || !formHasChanges;

    dispatch(updateHeaderButtonDisabled(isSubmitDisabled));
  }, [formValues, initialFormValues, dispatch]);

  useEffect(() => {
    setFormValues(initialValues);
    setInitialFormValues(initialValues);
  }, [initialValues]);

  useEffect(() => {
    dispatch(updateHeaderButtonAction(handleSubmit));
  }, [formValues]);

  return (
    <form
      onSubmit={(e) => e.preventDefault()}
      className={style.form__container}
    >
      <section className={style.form__section} ref={formulaFormRef}>
        <TextInput
          label="Nombre de la regla"
          placeholder="Nombre"
          value={capitalizeFirstLetter(formValues.name)}
          handleChange={handleInputChange}
          name="name"
          width={"100%"}
          inputProps={{ type: "text" }}
          handleBlur={handleBlur}
          error={errors.name}
          touched={touched.name}
          required
          disabled={false}
        />
        <Rules
          formValues={formValues}
          handleNestedInputChange={handleNestedInputChange}
          handleBlur={handleBlur}
          errors={errors}
          touched={touched}
          disabled={false}
        />
      </section>
      <section className={style.form__section} ref={membersFormRef}>
        <TeamMembers
          teamMembers={formValues.teamMembers}
          handleTeamMemberChange={handleTeamMemberChange}
          errors={errors}
          touched={touched}
          disabled={false}
        />
      </section>
      <section className={style.form__section} ref={itemsAppliedFormRef}>
        <AppliedProducts
          appliedItems={formValues.products}
          handleAddItem={(id) => handleAddItem(id, "product")}
          handleRemoveItem={(id) => handleRemoveItem(id, "product")}
          handleNestedInputChange={handleNestedInputChange}
          errors={errors}
          touched={touched}
          disabled={false}
        />
        <AppliedServices
          appliedItems={formValues.services}
          handleAddItem={(id) => handleAddItem(id, "service")}
          handleRemoveItem={(id) => handleRemoveItem(id, "service")}
          handleNestedInputChange={handleNestedInputChange}
          errors={errors}
          touched={touched}
          disabled={false}
        />
      </section>
    </form>
  );
};

export default CommissionForm;
