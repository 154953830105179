import { BrowserRouter as Router } from "react-router-dom";
import ContextRoute from "@Routes/context.route";
import { Provider } from "react-redux";
import { store } from "@ReduxService/index";
import { GlobalComponents } from "@Components/wrappers/GlobalComponents/GlobalComponents";

// Route page of the application
const App = () => {
  return (
    <Provider store={store}>
      <Router basename="/comisiones">
        <ContextRoute />
        <GlobalComponents />
      </Router>
    </Provider>
  );
};

export default App;
