import { IEmployee } from "@Models/interfaces/commissions";
import { AppDispatch } from "@ReduxService/index";
import {
  updateError,
  updateLoading,
} from "@ReduxService/states/commissionsSlice";
import { updateNotification } from "@ReduxService/states/notificationSlice";
import { getEmployees_service } from "@Services/commissions/getEmployees";

export const getEmployees = async (dispatch: AppDispatch) => {
  dispatch(updateLoading(true));
  const response = await getEmployees_service();
  dispatch(updateLoading(false));

  if (!response.isSuccess) {
    dispatch(updateError(true));
    dispatch(
      updateNotification({
        status: response.status,
        message: response.message,
        toastTimeDuration: 4000,
      }),
    );

    return response;
  }

  const employees: IEmployee[] = response.data
    .filter((employee: any) => employee.name.trim() !== "")
    .map((employee: any) => {
      return {
        id: employee.idEmployee,
        name: employee.name,
        lastName: employee.lastName,
      };
    });

  return {
    ...response,
    data: employees,
  };
};
