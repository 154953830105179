import { employeesBaseUrl, putData } from "@Services/constServices";

export const updateCommission_service = async (payload: any) => {
  const response = putData(
    `${employeesBaseUrl}/CommissionEmployee/EditCommisionEmployee`,
    payload,
    "Comisión actualizada correctamente",
    "Error al modificar la comisión. Intente de nuevo.",
  );

  return response;
};
