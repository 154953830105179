import { employeesBaseUrl, postData } from "@Services/constServices";

export const createCommission_service = async (payload) => {
  const response = postData(
    `${employeesBaseUrl}/CommissionEmployee/CreateCommisionEmployeeController`,
    payload,
    "Comisión creada correctamente",
    "Error al crear la comisión. Intente de nuevo.",
  );

  return response;
};
